<template>
  <div class="invite">
    <Top title="二维码" />
    <div class="pt44">
      <div class="bg-block mt10 flex-center column">
        <!-- <div ref="qrUrlCode" v-longpress="touchCode"></div> -->
        <img src="@/assets/img/code.jpg" />
        <span class="fz14 mt30 block forbiddenTextCopy"
          >重庆吼吼科技有限公司</span
        >
        <span class="fz12 mt15 block forbiddenTextCopy ft-grey2"
          >邀请人员微信扫码</span
        >
        <span class="fz12 mt5 block forbiddenTextCopy ft-grey2"
          >完善信息后,可加入到当前企业下</span
        >
      </div>
    </div>
    <!-- <div class="flex-center column bottom-fiexd flex-center1">
      <span
        class="iconfont icon-hps-appweixin fz24"
        style="color: rgb(40, 196, 69)"
      ></span>
      <span>微信好友</span>
    </div> -->
  </div>
</template>
<script>
// import wx from "weixin-js-sdk";
import Top from "@/components/top/top";
import QRCode from "qrcodejs2";
export default {
  components: {
    Top,
  },
  mounted() {
    // this.createImgUrl();
  },
  methods: {
    createImgUrl() {
      let content = "https://www.baidu.com";
      new QRCode(this.$refs["qrUrlCode"], {
        text: content,
        window: 200,
        height: 250,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
    touchCode() {
      alert(1);
    },
  },
};
</script>
<style lang="less" scoped>
.bg-block {
  padding: 6rem 0;
}
.align-center {
  text-align: center;
}
.forbiddenTextCopy {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}
</style>